import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFireEvent } from "@pydantic/fastui/dist/events";
import * as React from "react";
import { Button } from "react-bootstrap";
import {
  Network as VisNetwork,
  Node as VisNetworkNode,
  Edge as VisNetworkEdge,
  DataInterfaceNodes,
  DataInterfaceEdges,
  DataSet as VisDataSet,
} from "vis-network/standalone";

export function KnowledgeGraphNode() {
  console.error(
    "Cannot use KnowledgeGraphNode outside of a KnowledgeGraph component"
  );

  return null;
}

export function KnowledgeGraphEdge() {
  console.error(
    "Cannot use KnowledgeGraphNode outside of a KnowledgeGraph component"
  );

  return null;
}

interface Node {
  type: "Custom";
  subType: "KnowledgeGraphNode";

  data: {
    id: number;
    label: string;
    onClick: unknown;
    color: string;
    title: string;
  };
}

interface Edge {
  type: "Custom";
  subType: "KnowledgeGraphEdge";
  data: {
    source: number;
    target: number;
    label: string;
    onClick: unknown;
    strength?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    confidence?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    polarity?: 0 | 1;
  };
}

interface KnowledgeGraphProps {
  data: {
    nodes: Node[];
    edges: Edge[];
  };
}

function addLineBreaks(input: string, options: { maxLength: number }) {
  let maxLength = options.maxLength;

  const parts = input.split(" ");

  let chunks = [];

  let chunk = "";

  let index = 0;

  while (index < parts.length) {
    const part = parts[index];
    index++;

    if (part.length > maxLength) {
      maxLength = part.length;
      index = 0;
      chunk = "";
      chunks = [];
    }

    if (chunk.length + part.length + 1 <= maxLength) {
      chunk = chunk ? `${chunk} ${part}` : part;
    } else {
      chunks.push(chunk);
      chunk = part;
    }

    if (index == parts.length) {
      chunks.push(chunk);
    }
  }

  return chunks.join("\n");
}

function ellipsis(input: string, options: { lines: number }) {
  let parts = input.split("\n");

  if (parts.length <= options.lines) {
    return input;
  }

  parts = parts.slice(0, options.lines);

  const maxLength = parts.reduce(
    (prev, part) => (prev >= part.length ? prev : part.length),
    0
  );

  const last = parts[parts.length - 1];

  parts[parts.length - 1] =
    `${last.slice(0, Math.min(maxLength - 3, last.length)).trim()}...`;

  return parts.join("\n");
}

export function KnowledgeGraph(props: KnowledgeGraphProps) {
  const { data } = props;

  const container = React.useRef<HTMLDivElement>();
  const network = React.useRef<VisNetwork>(null);

  const { fireEvent } = useFireEvent();

  const [isPaused, setIsPaused] = React.useState(false);

  const [nodes, edges, eventsNodes, eventsEdges] = React.useMemo<
    [
      DataInterfaceNodes,
      DataInterfaceEdges,
      Map<number, unknown>,
      Map<string, unknown>,
    ]
  >(() => {
    const validEdges = data.edges.filter(
      (edge) => edge.subType === "KnowledgeGraphEdge" && edge.type === "Custom"
    );

    const inbound: Record<number, number> = {};
    const edges: VisNetworkEdge[] = [];
    const eventsEdges = new Map<string, unknown>();

    for (const index in validEdges) {
      const edge = validEdges[index];

      inbound[edge.data.target] = (inbound[edge.data.target] ?? 0) + 1;

      const { polarity, source, target, label, onClick, confidence, strength } =
        edge.data;

      if (onClick) {
        eventsEdges.set(index, edge.data.onClick);
      }

      const tooltip = [
        confidence != null ? `Sterkte: ${(strength * 100) / 10}%` : undefined,
        confidence != null
          ? `Zekerheid: ${(confidence * 100) / 10}%`
          : undefined,
        polarity != null
          ? `Polariteit: ${polarity === 0 ? "Negatief" : "Positief"}`
          : undefined,
      ]
        .filter(Boolean)
        .join("\n");

      edges.push({
        id: index,
        from: source,
        to: target,
        label: label,
        color: {
          color:
            polarity != null
              ? polarity === 1
                ? "#6BC581"
                : "#AF0000"
              : undefined,
          inherit: false,
        },
        width: (strength ?? 1) * 1,
        dashes: confidence != null ? [10, (10 - confidence) * 2.5] : undefined,
        title: tooltip ? tooltip : undefined,
        arrows: "to",
      });
    }

    const validNodes = data.nodes.filter(
      (node) => node.subType === "KnowledgeGraphNode" && node.type === "Custom"
    );

    const nodes: VisNetworkNode[] = [];
    const eventsNodes = new Map<number, unknown>();

    for (const node of validNodes) {
      if (node.data.onClick) {
        eventsNodes.set(node.data.id, node.data.onClick);
      }

      const label = ellipsis(
        addLineBreaks(node.data.label, { maxLength: 20 }),
        { lines: 2 }
      );

      const title = ellipsis(
        addLineBreaks(node.data.title, { maxLength: 50 }),
        { lines: 2 }
      );

      const fontSize =
        16 + (32 / 20) * Math.min(inbound[node.data.id] ?? 0, 20);
      const marginY = 8 + (16 / 20) * Math.min(inbound[node.data.id] ?? 0, 20);
      const marginX = 12 + (24 / 20) * Math.min(inbound[node.data.id] ?? 0, 20);

      nodes.push({
        id: node.data.id,
        label,
        title: node.data.title,
        shape: "box",
        color: node.data.color,
        font: {
          size: fontSize,
        },
        margin: {
          top: marginY,
          bottom: marginY,
          right: marginX,
          left: marginX,
        },
      });
    }

    return [
      new VisDataSet(nodes),
      new VisDataSet(edges),
      eventsNodes,
      eventsEdges,
    ];
  }, [data.nodes, data.edges]);

  React.useEffect(() => {
    if (!container.current) {
      return;
    }

    network.current = new VisNetwork(
      container.current,
      {
        nodes: nodes,
        edges: edges,
      },
      {
        physics: {
          solver: "repulsion",
          repulsion: {
            nodeDistance: 160,
            springLength: 400,
            springConstant: 0.05,
          },
          stabilization: {
            enabled: true,
            iterations: 100,
          },
        },
        layout: {
          improvedLayout: false,
        },

        nodes: {
          shape: "box",
          color: "black",
          font: {
            color: "white",
          },
        },
        edges: {
          arrows: {
            to: { enabled: true, scaleFactor: 1, type: "arrow" },
          },
        },
        interaction: {
          hover: true,
          selectConnectedEdges: false,
        },
      }
    );

    const clickNode = (params) => {
      const [node] = params.nodes;

      if (node && eventsNodes.has(node)) {
        fireEvent(eventsNodes.get(node));
      }
    };

    const clickEdge = (params) => {
      const [edge] = params.edges;

      if (edge && eventsEdges.has(edge)) {
        fireEvent(eventsEdges.get(edge));
      }
    };

    const hover = () => {
      // @ts-expect-error - It's available
      const el = n?.body?.container;

      if (el) {
        el.style.cursor = "pointer";
      }
    };
    const blur = () => {
      // @ts-expect-error - It's available
      const el = n?.body?.container;

      if (el) {
        el.style.cursor = "auto";
      }
    };

    network.current.on("selectNode", clickNode);
    network.current.on("selectEdge", clickEdge);
    network.current.on("hoverNode", hover);
    network.current.on("hoverEdge", hover);
    network.current.on("blurNode", blur);
    network.current.on("blurEdge", blur);

    const n = network.current;

    return () => {
      n.off("selectNode", clickNode);
      n.off("selectEdge", clickEdge);
      n.off("hoverNode", hover);
      n.off("hoverEdge", hover);
      n.off("blurNode", blur);
      n.off("blurEdge", blur);

      n.destroy();

      network.current = null;
    };
  }, [container, edges, nodes, fireEvent, eventsNodes, eventsEdges]);

  React.useEffect(() => {
    network.current.setOptions({ physics: { enabled: !isPaused } });
  }, [isPaused]);

  return (
    <>
      <div className="w-100 h-100 position-relative">
        <div
          ref={container}
          className="position-absolute bottom-0 top-0 start-0 end-0"
        ></div>
      </div>
      <div className="d-flex flex-row w-100 justify-content-between position-sticky bottom-0">
        <div className="me-auto mt-auto position-absolute bottom-0 start-0 p-1">
          <Button
            variant="link"
            onClick={() => {
              setIsPaused(!isPaused);
            }}
            className="text-decoration-none text-dark"
          >
            <FontAwesomeIcon icon={isPaused ? faPlay : faPause} />

            <span className="ms-2 small">{isPaused ? "Resume" : "Pause"}</span>
          </Button>
        </div>

        <div className="ms-auto d-flex flex-column p-2 gap-2 position-absolute bottom-0 end-0">
          <Button
            variant="secondary"
            onClick={() => {
              network.current.moveTo({
                scale: network.current.getScale() + 0.2,
              });
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              network.current.moveTo({
                scale: network.current.getScale() - 0.2,
              });
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
          </Button>
        </div>
      </div>
    </>
  );
}
