import { components, models, useClassName } from "@pydantic/fastui";

export const Footer = (props: models.Footer) => {
  const links = props.links.map((link) => {
    link.mode = link.mode || "footer";
    return link;
  });
  const extraProp = useClassName(props as never, { el: "extra" });
  return (
    <footer className={useClassName(props as never)}>
      <ul className={useClassName(props as never, { el: "link-list" })}>
        {links.map((link, i) => (
          <li key={i} className="nav-item">
            <components.LinkComp {...link} />
          </li>
        ))}
      </ul>
      {props.extraText ? (
        <div className={extraProp}>{props.extraText}</div>
      ) : null}
    </footer>
  );
};
