import {
  components,
  events,
  renderClassName,
  EventContextProvider,
  models,
} from "@pydantic/fastui";
import BootstrapToast from "react-bootstrap/Toast";
import BootstrapToastContainer from "react-bootstrap/ToastContainer";

export const Toast = (props: models.Toast) => {
  const { className, title, body, position, openTrigger, openContext } = props;

  const { eventContext, fireId, clear } = events.usePageEventListen(
    openTrigger,
    openContext,
  );

  return (
    <EventContextProvider context={eventContext}>
      <BootstrapToastContainer
        position={position}
        className="position-fixed bottom-0 end-0 p-3"
      >
        <BootstrapToast
          className={renderClassName(className)}
          show={!!fireId}
          onClose={clear}
        >
          <BootstrapToast.Header>
            <strong className="me-auto">{title}</strong>
          </BootstrapToast.Header>
          <BootstrapToast.Body>
            <components.AnyCompList propsList={body} />
          </BootstrapToast.Body>
        </BootstrapToast>
      </BootstrapToastContainer>
    </EventContextProvider>
  );
};
