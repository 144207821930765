import { FastUI, renderClassName } from "@pydantic/fastui";
import * as React from "react";

import { classNameGenerator, render } from "./components";

export default function App() {
  return (
    <FastUI
      APIRootUrl={getMetaContent("fastui:APIRootUrl") || "/api"}
      APIPathMode={
        getMetaContent("fastui:APIPathMode") as undefined | "append" | "query"
      }
      APIPathStrip={getMetaContent("fastui:APIPathStrip")}
      classNameGenerator={classNameGenerator}
      customRender={render}
      NotFound={NotFound}
      Transition={Transition}
    />
  );
}

function getMetaContent(name: string): string | undefined {
  return (
    document.querySelector(`meta[name="${name}"]`)?.getAttribute("content") ||
    undefined
  );
}

const NotFound = ({ url }: { url: string }) => (
  <div className="container mt-5 text-center">
    <h1>Page not found</h1>
    <p>
      No page found at <code>{url}</code>.
    </p>
  </div>
);

const Transition = ({
  children,
  transitioning,
}: {
  children: React.ReactNode;
  transitioning: boolean;
}) => {
  /**
   * A really weird hack to let fastui communicate with react that the html tree has been remounted
   * Otherwise it will persist data which was left in the tree by an earlier page visit
   */
  const tick = React.useRef<number>(0);

  React.useEffect(() => {
    tick.current++;
  }, [transitioning])

  return (
    <>
      <div
        className={renderClassName({ "transition-overlay": true, transitioning })}
      />
      <div style={{ display: 'contents' }} key={tick.current}>
        {children}
      </div>
    </>
  )
};
