import { AnyComp, AnyCompList } from "@pydantic/fastui/dist/components";
import { Tabs as BootstrapTabs, Tab as BootstrapTab } from "react-bootstrap";

type AnyCompProps = Parameters<typeof AnyComp>[0];

interface Tab {
  type: "Custom";
  subType: "Tab";

  data: {
    id: string;
    title: string;
    content: AnyCompProps[];
  };
}

export function Tab() {
  console.error("Cannot use Tab outside of a Tabs component");

  return null;
}

interface TabsProps {
  type: "Custom";
  subType: "Tabs";

  data: {
    components: Tab[];
  };
}

export function Tabs(props: TabsProps) {
  const { data } = props;

  const tabs = data.components.filter(
    (component) => component.subType === "Tab" && component.type === "Custom"
  );

  return (
    <BootstrapTabs>
      {tabs.map((tab) => {
        return (
          <BootstrapTab
            mountOnEnter
            unmountOnExit
            title={tab.data.title}
            eventKey={tab.data.id}
            key={tab.data.id}
          >
            <AnyCompList propsList={tab.data.content} />
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
}
