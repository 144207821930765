import { AnyComp } from "@pydantic/fastui/dist/components"
import {
  OverlayTrigger as BootstrapOverlayTrigger,
  Popover as BootstrapPopover,
} from "react-bootstrap"

import { IconButton } from "./IconButton"

type AnyCompProps = Parameters<typeof AnyComp>[0]

interface PopoverProps {
  data: {
    trigger: AnyCompProps;
    content: AnyCompProps;
    title?: string;
  };
}

export function Popover(props: PopoverProps) {
  const { data } = props;

  let { trigger } = data;

  if (trigger.subType !== "IconButton") {
    trigger = undefined;
  }


  return (
    <BootstrapOverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <BootstrapPopover>
          {data.title ? (
            <BootstrapPopover.Header>{data.title}</BootstrapPopover.Header>
          ) : null}
          <BootstrapPopover.Body>
            <AnyComp {...data.content} />
          </BootstrapPopover.Body>
        </BootstrapPopover>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <IconButton
          ref={ref}
          {...triggerHandler}
          {...data.trigger}
          data={{
            ...data.trigger.data,
            onClick: () => {
              if (data.trigger.data.onClick) {
                data.trigger.data.onClick();
              }
            },
          }}
        />
      )}
    </BootstrapOverlayTrigger>
  );
}
