import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnyComp, AnyCompList } from "@pydantic/fastui/dist/components";
import * as React from "react";
import { Button, CloseButton } from "react-bootstrap";

import { KnowledgeGraph } from "./KnowledgeGraph";

type AnyCompProps = Parameters<typeof AnyComp>[0];

export function VisualizationFilters() {
  return null;
}

interface FilterProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

function Filters(props: FilterProps) {
  const { onClose, children, isOpen } = props;

  return (
    <div
      style={{ width: "calc(100%/3)" }}
      className={[
        "p-2 flex-shrink-0 flex-grow-0 col-4 position-sticky top-0 bottom-0 mb-auto",
        isOpen ? "d-block" : "d-none",
      ]
        .filter(Boolean)
        .join("  ")}
    >
      <div className="bg-white bg border-light-subtle border rounded-1 bg-body p-2 w-100 d-flex flex-column">
        <div className="d-flex flex-row w-100 align-items-center mb-3">
          <h2 className="h4 mr-2 mb-0 w-100">Filters</h2>

          <CloseButton
            className="ml-auto flex-grow-0 flex-shrink-0 m-1"
            onClick={onClose}
          />
        </div>

        {children}
      </div>
    </div>
  );
}

export interface VisualizationProps {
  data: {
    graph: AnyCompProps;
    filters: AnyCompProps[];
    actions: AnyCompProps[];
  };
}

export function Visualization(props: VisualizationProps) {
  const { data } = props;

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  let graph;

  if (data.graph.subType === "KnowledgeGraph") {
    graph = <KnowledgeGraph {...data.graph} />;
  }

  const hasFilters = data.filters.length > 0;
  const hasActions = data.actions.length > 0;

  return (
    <div className="ratio ratio-4x3 position-relative">
      <div className="border border-dark bg-light-subtle rounded-1 align-items-center d-flex flex-row">
        <div className="position-absolute start-0 end-0 bottom-0 top-0">
          {graph}
        </div>
        {hasFilters ? (
          <Filters
            onClose={() => {
              setIsOpen(false);
            }}
            isOpen={isOpen}
          >
            <AnyCompList propsList={data.filters} />
          </Filters>
        ) : null}
        <div
          style={{ width: "calc(100%/3*2)", pointerEvents: "none" }}
          className="flex-grow-1 flex-shrink-1 h-100 position-relative"
        >
          <div className="position-sticky top-0 bottom-0 start-0">
            <div
              className="p-2 d-flex flex-row gap-2"
              style={{ pointerEvents: "auto" }}
            >
              {hasFilters && !isOpen ? (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </Button>
              ) : null}

              {hasActions ? <AnyCompList propsList={data.actions} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
