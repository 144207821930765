import { components } from "@pydantic/fastui";
import { AnyComp, AnyCompList } from "@pydantic/fastui/dist/components";
import { useFireEvent } from "@pydantic/fastui/dist/events";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import BootstrapNavbar from "react-bootstrap/Navbar";

type AnyCompProps = Parameters<typeof AnyComp>[0];

interface CustomComponent<
  T extends string,
  Data extends Record<"data", Record<string, unknown>>,
> {
  type: "Custom";
  subType: T;

  data: Data["data"];
}

interface NavbarBrandProps {
  data: {
    onClick: never;
    content: AnyCompProps[];
  };
}

export function NavbarBrand(props: NavbarBrandProps) {
  const { onClick, content } = props.data;

  const { fireEvent } = useFireEvent();

  return (
    <BootstrapNavbar.Brand
      className="d-flex align-items-center gap-2"
      style={
        onClick
          ? {
              cursor: "pointer",
            }
          : {}
      }
      onClick={() => {
        if (onClick) {
          fireEvent(onClick);
        }
      }}
    >
      <AnyCompList propsList={content} />
    </BootstrapNavbar.Brand>
  );
}

interface NavbarProps {
  data: {
    title: CustomComponent<"NavbarBrand", NavbarBrandProps>;
    links: AnyCompProps[];
    form: AnyCompProps;
    actions: AnyCompProps[];
  };
}

export function Navbar(props: NavbarProps) {
  let { title, links, form } = props.data;
  const { actions } = props.data;

  if (title.subType !== "NavbarBrand") {
    title = undefined;
  }

  if (form.type !== "Form") {
    form = undefined;
  }

  links = links.filter((link) => link.type == "Link");

  return (
    <BootstrapNavbar bg="light" expand="lg" className="border-bottom">
      <Container>
        {title ? <NavbarBrand {...title} /> : null}

        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <div className="d-flex flex-column flex-lg-row py-3 py-lg-0 w-100">
            {form ? <AnyComp {...form} /> : null}

            {links.length ? (
              <Nav className="me-auto">
                {links.map((link, index) => (
                  <components.LinkComp key={index} {...link} />
                ))}
              </Nav>
            ) : null}

            {actions.length > 0 ? (
              <div className="mt-3 mt-lg-0 ms-auto d-flex flex-row gap-2">
                {actions.map((action, index) => (
                  <AnyComp {...action} key={index} />
                ))}
              </div>
            ) : null}
          </div>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}
