import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFireEvent } from "@pydantic/fastui/dist/events";
import * as React from "react";
import { Button } from "react-bootstrap";

interface IconButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  data: {
    icon: string;
    onClick: never;
    type?: "submit" | "button" | "reset";
    variant?: "primary" | "secondary";
  };
}

export const IconButton = React.forwardRef(function IconButton(
  props: IconButtonProps,
  ref: React.Ref<HTMLButtonElement>
) {
  const { data, onClick } = props;

  const { fireEvent } = useFireEvent();

  console.log(props);

  return (
    <Button
      variant={data.variant ?? "secondary"}
      type={data.type}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }

        if (data.onClick) {
          fireEvent(data.onClick);
        }
      }}
      ref={ref}
    >
      <FontAwesomeIcon icon={fas[data.icon]} />
    </Button>
  );
});
